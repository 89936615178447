<template>
	<main>
		<HeaderTab
			:title="getTrad('monte.contract_list')" 
			:return_action="'navigationBack'"
		/>
				
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box" id="marelist">
							<List />
							<!-- <CustomTable
								id_table="contract_client_area"
								sortBy="contract_num"
								:busy.sync="table_busy"
								:items="contracts"
								primaryKey="contract_id"
								:hide_if_empty="true"
								:rawColumns="rawColumnNames"
								:hrefsRoutes="config_table_hrefs"
                                :columsAdd="columns_adds"
                                :externSlotColumns="extern_slot_columns"
								ref="contractTable"
								:hasExportOption="false"
								:showPagination="false"
							>
                                <template v-slot:custom-slot-cell(download)="{ data }">
                                    <span class="d-flex justify-content-center">
		                                <font-awesome-icon :icon="['fas', 'download']" @click="callPrintContracts(data.contract_id)" />
                                    </span>
                                </template>
                                <template v-slot:custom-slot-cell(validate)="{ data }">
                                    <button @click.prevent="prepareContract(data.contract_id)" v-if="data.avenant_status_initial.indexOf('initial') > -1 && marketplace == 'paybox'" class="btn btn-primary">{{ $t('monte.valider_contract') }}</button>
                                </template>
                                <template v-slot:custom-slot-cell(reserver)="{ data }">
                                    <button @click.prevent="openReservationModal(data)" v-if="data.avenant_status == 'Signé' && (data.type_monte.contracttypemonte_type == 'iar' || data.type_monte.contracttypemonte_type == 'iart') && data.cmep && data.stallion_stationnement" class="btn btn-primary">{{ $t('monte.reserver') }}</button>
                                </template>
                                <template v-slot:custom-slot-cell(document_dn)="{ data }">
                                    <span v-if="data.document_dn" class="d-flex justify-content-center">
		                                <font-awesome-icon :icon="['fas', 'eye']" @click="quickPreview(data.document_dn)" />
                                    </span>
                                </template>
                                <template v-slot:custom-slot-cell(document_as)="{ data }">
                                    <span v-if="data.document_as" class="d-flex justify-content-center">
		                                <font-awesome-icon :icon="['fas', 'eye']" @click="quickPreview(data.document_as)" />
                                    </span>
                                </template>
                            </CustomTable> -->
							<!-- <div class="row">
								
								<div class="col-auto ml-auto">
									<b-button @click="checkInfosTiers" variant="primary">{{ $t('action.ajouter_contract') }} <font-awesome-icon :icon="['fal', 'plus']"/></b-button>
								</div>
							</div>
							<div v-if="table_busy">
								<LoadingSpinner />
							</div>
							<div v-else class="row">
								<div v-if="contracts.length == 0" class="col-12 text-center">
									{{ $t('global.list_empty') }}
								</div>
								<div v-for="(season, index) in seasons" class="col-12" :key="season">
									<h3>
										<a href="#" @click.prevent="openContractsList(season, index)">
											{{ season }}
											<font-awesome-icon :icon="['fal', 'angle-down']" v-if="selected_index.includes(index)" />
											<font-awesome-icon :icon="['fal', 'angle-up']" v-else />
										</a>
									</h3>
									<div v-if="index == 0" :class="'row ' + season + '_' + index">
										<div v-for="contract in contracts[season]" class="col-12 col-lg-4 mb-2 mb-lg-3" :key="contract.contract_id">
											<div class="mares" @click="goToContract(contract.contract_id)">
												<span>
													<span class="text-primary">{{ contract.season_label }} - {{ contract.contract_num }}</span>
													<b>{{ contract.stallion_nom }} * {{ contract.mare_nom }}</b>
													<i>{{ contract.sms_status }} - <span class="text-primary">{{ contract.avenant_status }}</span></i>
												</span>
											</div>
										</div>
									</div>
									<div v-else :class="'row d-none ' + season + '_' + index">
										<div v-for="contract in contracts[season]" class="col-12 col-lg-4 mb-2 mb-lg-3" :key="contract.contract_id">
											<div class="mares" @click="goToContract(contract.contract_id)">
												<span>
													<span class="text-primary">{{ contract.season_label }} - {{ contract.contract_num }}</span>
													<b>{{ contract.stallion_nom }} * {{ contract.mare_nom }}</b>
													<i>{{ contract.sms_status }} - <span class="text-primary">{{ contract.avenant_status }}</span></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<!-- <div v-else>
				<ContractShutter :contract_id="contract_id"></ContractShutter>
			</div> -->


			<!-- <b-modal ref="modal" hide-footer>
				<template v-slot:modal-title>
					{{ $t("action.online_paiement") }}
				</template>

				<div v-if="!modal_loaded">
					<LoadingSpinner class="col-12" />
				</div>

				
				<div>
					
					<template v-if="marketplace == 'paybox'">
						<form @submit.prevent="payContract">

							<b-button class="mt-3" block @click.prevent="payContract">{{ $t("marketplace.payer") }}</b-button>
						</form> -->

						<!-- Formulaire caché, il sert a renvoyer vers atos grace a une arnaque moldave -->
						<!-- <form method="POST" :action="paybox_infos.url" id="redirectForm" class="d-none">
							<input type="hidden" name="PBX_SITE" :value="paybox_infos.PBX_SITE">
							<input type="hidden" name="PBX_RANG" :value="paybox_infos.PBX_RANG">
							<input type="hidden" name="PBX_IDENTIFIANT" :value="paybox_infos.PBX_IDENTIFIANT">
							<input type="hidden" name="PBX_TOTAL" :value="paybox_infos.PBX_TOTAL">
							<input type="hidden" name="PBX_DEVISE" :value="paybox_infos.PBX_DEVISE">
							<input type="hidden" name="PBX_RUF1" value="GET">
							<input type="hidden" name="PBX_CMD" :value="paybox_infos.PBX_CMD">
							<input type="hidden" name="PBX_PORTEUR" :value="paybox_infos.PBX_PORTEUR">
							<input type="hidden" name="PBX_REPONDRE_A" :value="paybox_infos.PBX_REPONDRE_A">
							<input type="hidden" name="PBX_RETOUR" :value="paybox_infos.PBX_RETOUR">
							<input type="hidden" name="PBX_EFFECTUE" :value="paybox_infos.PBX_EFFECTUE">
							<input type="hidden" name="PBX_ANNULE" :value="paybox_infos.PBX_ANNULE">
							<input type="hidden" name="PBX_REFUSE" :value="paybox_infos.PBX_REFUSE">
							<input type="hidden" name="PBX_HASH" value="SHA512">
							<input type="hidden" name="PBX_TIME" :value="paybox_infos.PBX_TIME">
							<input type="hidden" name="PBX_SHOPPINGCART" :value="paybox_infos.PBX_SHOPPINGCART">
							<input type="hidden" name="PBX_BILLING" :value="paybox_infos.PBX_BILLING">
							<input type="hidden" name="PBX_HMAC" :value="paybox_infos.PBX_HMAC">
							<button id="button_go_paybox" onclick="">valider</button>
						</form>
					</template>
				</div>
			</b-modal>

			<TiersModal ref="tiers_modal" @submit="tiersInfosChecked" />

			<ReservationModal ref="reservation_modal" />

	        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="preview_url = null">
	            <template v-slot:modal-title>
	                {{ $t("action.previsualiser") }}
	            </template>

	            <iframe v-if="preview_url != null" :src="preview_url" height="1000px" width="100%" style="position:relative"></iframe>
	        </b-modal> -->
		</div>
	</main>
</template>

<script type="text/javascript">
	export default {
		name: "ContractList",
		mixins: [],
		data () {
			return {}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				//
			}
		},
		components: {
			HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
			List : () => import('@/components/Contract/List')
		},
	}
</script>
